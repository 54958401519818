<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <space-summary v-if="showSpaceSummary" :spaceId="itemId" />
      </template>
    </ui-component-drawer>

    <modal-add-space
      v-if="showAddSpace"
      :showModal="showAddSpace"
      :onClickCancel="hideModal"
    />

    <modal-delete-space
      v-if="showDeleteSpace"
      :showModal="showDeleteSpace"
      :space="selectedSpace"
      :onClickCancel="hideModal"
    />

    <modal-edit-space
      v-if="showEditSpace"
      :showModal="showEditSpace"
      :isEmployee="true"
      :space="selectedSpace"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showAdd="true"
      :addFunction="setShowAddSpace"
      :addLabel="'Add space'"
    >
      <template v-slot:title>Spaces</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-narrow">
      <thead>
        <tr>
          <th width="100">
            {{ $t('Components.Carts.CartMeetingspaces.Table_Seats') }}
          </th>
          <th>{{ $t('Components.Carts.CartMeetingspaces.Table_Name') }}</th>
          <th width="200">
            {{ $t('Components.Carts.CartMeetingspaces.Table_Setting') }}
          </th>
          <th width="100">
            {{ $t('Components.Carts.CartMeetingspaces.Table_Time') }}
          </th>
          <th width="75" class="has-text-right">
            {{ $t('Components.Carts.CartMeetingspaces.Table_Price') }}
          </th>
          <th width="50" class="has-text-right">
            {{ $t('Components.Carts.CartMeetingspaces.Table_Tax') }}
          </th>
          <th width="100" class="has-text-right">
            {{ $t('Components.Carts.CartMeetingspaces.Table_Total') }}
          </th>
          <th width="50">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(meetingspace, index) in cart.Spaces" :key="index">
          <td>{{ meetingspace.Seats }}x</td>
          <td>
            {{ meetingspace.SpaceName }}
            <a @click="setShowSpaceSummary(meetingspace.SpaceId)">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'info-circle']" />
              </span>
            </a>
          </td>
          <td>{{ meetingspace.SettingId | getSettingName }}</td>
          <td>
            {{ meetingspace.StartMinutes | minutesToTime }} -
            {{ meetingspace.EndMinutes | minutesToTime }}
          </td>
          <td class="has-text-right">
            <span>{{
              meetingspace.PricePerSeat
                | toCurrency(languageCulture, cart.CurrencyIso)
            }}</span>
          </td>
          <td class="has-text-right">{{ meetingspace.TaxPercentage }}%</td>
          <td class="has-text-right">
            <span>{{
              meetingspace.PriceTotal
                | toCurrency(languageCulture, cart.CurrencyIso)
            }}</span>
          </td>
          <td width="50" v-if="!isLocked">
            <div class="field is-grouped">
              <p class="control">
                <a @click="setShowEditSpace(meetingspace, index)">
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                </a>
              </p>

              <p class="control">
                <a @click="setShowDeleteSpace(meetingspace, index)">
                  <span class="icon is-small has-text-danger">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </span>
                </a>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6">
            {{ $t('Components.Carts.CartMeetingspaces.Table_Subtotal') }}
          </td>
          <td class="has-text-right">
            <span>{{
              total | toCurrency(languageCulture, cart.CurrencyIso)
            }}</span>
          </td>
          <td>&nbsp;</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import priceProvider from '@/providers/price'

const ModalAddSpace = () => import('@/components/Carts/ModalAddSpace')
const ModalDeleteSpace = () => import('@/components/Carts/ModalDeleteSpace')
const ModalEditSpace = () => import('@/components/Carts/ModalEditSpace')
const SpaceSummary = () => import('@/components/Spaces/SpaceSummary')

export default {
  components: {
    'modal-add-space': ModalAddSpace,
    'modal-delete-space': ModalDeleteSpace,
    'modal-edit-space': ModalEditSpace,
    'space-summary': SpaceSummary
  },
  props: {
    isLocked: {
      default: false,
      type: Boolean
    },

    languageCulture: {
      type: String,
      default: 'nl-NL'
    }
  },

  data() {
    return {
      selectedSpace: null,
      showAddSpace: false,
      showDeleteSpace: false,
      showEditSpace: false,
      showSpaceSummary: false,
      showSideDrawer: false
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),

    total() {
      let total = 0.0

      if (this.cart.Spaces.length > 0) {
        for (let i = 0; i <= this.cart.Spaces.length; i++) {
          let meetingspace = this.cart.Spaces[i]
          if (meetingspace && meetingspace.PriceTotal > 0) {
            total = total + meetingspace.PriceTotal
          }
        }
      }

      return total
    }
  },

  created() {},

  methods: {
    minutesToTime(minutes) {
      let hour = Number(Math.floor(minutes / 60))
      let minute = Number(minutes - hour * 60)

      return (
        (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute
      )
    },

    setShowAddSpace() {
      this.showAddSpace = true
    },

    setShowDeleteSpace(meetingspace, index) {
      this.showDeleteSpace = true
      this.selectedSpace = meetingspace
    },

    setShowEditSpace(meetingspace, index) {
      this.showEditSpace = true
      this.selectedSpace = meetingspace
    },

    hideModal() {
      this.showAddSpace = false
      this.showDeleteSpace = false
      this.showEditSpace = false

      this.selectedSpace = null
    },

    setShowSpaceSummary(spaceId) {
      this.itemId = spaceId
      this.drawerTitle = 'Space detail'
      this.showSideDrawer = true
      this.showSpaceSummary = true
    },

    hideDrawer() {
      this.itemId = 0
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.showSpaceSummary = false
    }
  }
}
</script>
